<template>
  <div class="ml-sm-3"
       style="position: relative;">
    <v-carousel v-model="index"
                :show-arrows="hasImage"
                class="black"
                delimiter-icon="mdi-minus"
                height="auto"
                style="min-height: 200px; max-height: 300px;">
      <v-carousel-item v-for="(image, index) in image"
                       :key="index" style="position: relative;">
        <div v-if="hasImage" class="d-flex justify-center" style="height: 100%; width: 100%;">
          <a :href="image.pathImage" target="_blank">
            <img :alt="`img-id-${image.id}`" :src="image.pathImage" style="height: 300px; width: auto;">
          </a>
          <v-btn v-if="hasImage"
                 class="white--text primary"
                 icon
                 style="top: 8px; right: 8px; position: absolute; z-index: 6;"
                 @click="downloadButtonClick($event, image.pathImage)">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
        <v-img v-else :src="image.pathImage"
               contain
               height="300">
          <template v-slot:placeholder>
            <v-row align="center"
                   class="fill-height ma-0"
                   justify="center">
              <v-progress-circular
                color="white"
                indeterminate/>
            </v-row>
          </template>
        </v-img>
      </v-carousel-item>
    </v-carousel>
    <v-btn class="white--text primary"
           icon
           style="bottom: 8px; right: 8px; position: absolute; z-index: 6;"
           @click="openCamera">
      <v-icon>mdi-camera-plus-outline</v-icon>
    </v-btn>
    <v-btn v-if="hasImage"
           class="white primary--text"
           icon
           style="bottom: 8px; left: 8px; position: absolute; z-index: 6;"
           @click="warningDrawer=true">
      <v-icon>mdi-trash-can-outline</v-icon>
    </v-btn>
    <input
      v-if="mountInput"
      ref="cameraOpener"
      :accept="$vuetify.breakpoint.smAndUp ? 'image/*' : 'image/*capture=camera'"
      class="d-none"
      multiple
      type="file"
      @change="onFileChange">
    <v-dialog v-model="warningDrawer" max-width="250">
      <v-card>
        <v-card-text class="my__v-card-text">
          <div class="pa-4 text-center">
            <v-icon class="warning--text"
                    style="font-size: 57px;">
              mdi-alert-outline
            </v-icon>
            <div class="black--text font-weight-bold my-4 mb-5"
                 style="font-size: 1.5rem;">
              ยืนยันการลบรูปภาพ
            </div>
            <div class="d-flex justify-space-between"
                 style="width: 100%;">
              <v-btn color="grey"
                     outlined
                     style="height: 32px;"
                     @click="warningDrawer=false">
                ยกเลิก
              </v-btn>
              <v-btn color="blue white--text"
                     elevation="0"
                     style="height: 32px;"
                     @click="deleteImage();warningDrawer=false">
                ยืนยัน
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import utils from '@/assets/js/utils'

export default {
  name: 'StepTwoCarousel',
  data () {
    return {
      index: 0,
      mountInput: true,
      warningDrawer: false
    }
  },
  methods: {
    onFileChange (e) {
      if (e.target.files.length <= 0) return false
      this.mountInput = false
      const files = [...e.target.files]
      files.forEach(file => {
        const fileName = file.name
        const extension = fileName.split('.').pop()
        const validFileType = utils.validFileType.includes(extension)
        if (validFileType) {
          this.imageCallback(file)
        }
      })
      this.mountInput = true
    },
    openCamera () {
      this.$refs.cameraOpener.click()
      // const payload = {
      //   file: null,
      //   callback: this.imageCallback,
      //   header: 'อัพโหลดภาพถ่าย',
      //   subHeader: `${this.sectorId}${this.sectorNo} - ${this.componentId}${this.componentNo}`,
      //   showRemove: false,
      //   drawer: true,
      //   caption: '',
      //   removeCallback: (image) => {},
      //   showResponseDialog: false,
      //   onOpen: ['clickInput']
      // }
      // this.$store.commit('imageUploader/MUTATE_COMPONENT', payload)
    },
    deleteImage () {
      this.$store.commit('inspectionDamageDialog/SPLICE_IMAGE', this.index)
    },
    imageCallback (image) {
      if (image) {
        this.$store.commit('inspectionDamageDialog/APPEND_IMAGE', image)
        this.index = this.image.length - 1
      }
    },
    downloadButtonClick (e, path) {
      e.stopPropagation()
      const anchor = document.createElement('a')
      anchor.href = path
      anchor.download = path.split('/').slice(-1)[0]
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    }
  },
  computed: {
    ...mapState('inspectionDamageDialog', { sectorId: 'sectorId', sectorNo: 'sectorNo', componentId: 'componentId', componentNo: 'componentNo' }),
    ...mapState('inspectionDamageDialog', { pathImages: 'pathImages' }),
    hasImage () {
      return this.$store.state.inspectionDamageDialog.pathImages.length > 0
    },
    image: {
      get () {
        const image = this.$store.state.inspectionDamageDialog.pathImages
          .map(obj => {
            if (obj.file) {
              obj.pathImage = URL.createObjectURL(obj.file)
            }
            return obj
          })
        if (image.length <= 0) {
          return [{ id: 0, pathImage: './bmms_logo.png' }]
        } else {
          return image
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
